<template>
  <div class="referent">
    <HeadTitle :mainTitle="$t('manage_partner.edit') + partner"/>
    <div class="form">
      <div class="row g-3 align-items-center">
        <div class="col-auto">
          <label for="partner" class="col-form-label">{{ $t('manage_partner.label_input_partner') }}</label>
        </div>
        <div class="col-auto">
          <input type="text" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="$t('manage_partner.tooltip_external_company')" v-model="field_partner_name" id="partner" class="form-control" v-bind:class="{'is-invalid' : partnerError}" v-on:keyup.enter="searchPartner">
        </div>
        <div class="col-auto">
          <button @click="searchPartner" class="btn btn-primary">{{ $t('manage_partner.btn_valid') }}</button>
        </div>
        <div class="spinner-border text-primary" v-show="loadingPartner" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="col-auto" v-show="partnerError">
          <span style="color:red">{{ $t('manage_partner.error_check_partner') }}</span>
        </div>
      </div>
      <br>
      <div v-show="partnerSelected" style="margin-top:20px">
        <hr style="height:2px;border:none;color:#333;background-color:#333;">
        <br v-show="canChangeReferent">
        <div class="row g-3 align-items-center" v-show="canChangeReferent">
          <div class="col-2">
            <label for="referent" class="col-form-label">{{ $t('manage_partner.label_input_referent') }}</label>
          </div>
          <div class="col-5">
            <input type="email" v-model="referent" id="referent" class="form-control" v-bind:class="{'is-invalid' : referentError, 'is-valid' : referentValid}" @focusout="checkReferent">
          </div>
          <div class="col-auto">
            <button @click="changeReferent" v-bind:class="{disabled: !referentValid}" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ $t('manage_partner.btn_valid') }}</button>
          </div>
          <div class="col-auto" v-show="referentError">
            <span style="color:red">{{ $t('error.check_login') }}</span>
          </div>
          <div class="spinner-border text-primary" v-show="loadingReferent" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <br>
        <hr v-show="canChangeReferent">
        <br v-show="canChangeReferent">
        <div class="row g-3 align-items-center">
          <div class="col-2">
            <label for="new_manager" class="col-form-label">{{ $t('manage_partner.label_input_new_manager') }}</label>
          </div>
          <div class="col-4">
            <input type="email" v-model="new_manager" id="new_manager" class="form-control" v-bind:class="{'is-invalid' : managerError}" placeholder="example.sample@partner.com" v-on:keyup.enter="addManager">
          </div>
          <div class="col-auto">
            <button @click="addManager" class="btn btn-primary">{{ $t('manage_partner.btn_valid') }}</button>
          </div>
          <div class="spinner-border text-primary " v-show="loadingManager" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="col-auto" v-show="managerError">
            <span style="color:red">{{ $t('error.check_login') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-4"></div>
          <div class="col-1">
            <span style="font-size:3em">&#x2B07;</span>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-2">
            {{ $t('manage_partner.label_input_list_manager') }}
          </div>
          <div class="col-4">
            <select class="form-select" id="select_multiple_managers" size="5" multiple aria-label="size 3 select multiple">
              <option v-for="manager in partnerManagers" :id="manager.id" :value="manager.id" :key="manager.id">{{manager.login}}</option>
            </select>
          </div>
          <div class="col-auto" style="padding-left:8px">
            <button @click="removeSelectedManagers" class="btn btn-primary">{{ $t('manage_partner.btn_remove') }}</button>
          </div>
          <div class="spinner-border text-primary col-auto" v-show="loadingRemoveManager" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="col-auto" style="padding-left:8px">
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ $t('modal.response') }}</h5>
            </div>
            <div class="modal-body">
              <span v-if="isFailed" style='color:red'>{{ message }}</span>
              <span v-else>{{ message }}</span>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" v-show="loading" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button ref="closeModal" type="button" class="btn btn-secondary" v-bind:class="{disabled: loading}" data-bs-dismiss="modal">{{ $t('modal.close') }}</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HeadTitle from '../../components/HeadTitle.vue'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  name: 'PartnerManageView',
  components: {
    HeadTitle
  },
  data() {
    return {
        //Name of the external company on the field
        field_partner_name : "",
        //Login of the referent retrieved when retrieving an external company
        old_referent: "",
        //Referent on the field
        referent : "",
        //Id of the given valid referent
        idReferent: "",
        //Manager on the field
        new_manager :"",
        //Name of the external company, used for the title
        partner : this.$t('manage_partner.title_no_name'),
        //Message for the modal
        message : "",
        //First ID = users group, second ID = manager ID
        parterIds : [],
        //List of partner managers
        partnerManagers: [],
        //If the external company is retrieved, set to true to display interface
        partnerSelected: false,
        //Loading state for retrieving an external company
        loadingPartner: false,
        //Loading state for adding a manager
        loadingManager: false,
        //Loading state for changing the referent
        loadingReferent: false,
        //Loading state for removing a manager
        loadingRemoveManager: false,
        //Loading state for the modal when adding a referent (which can be a long action)
        loading : false,
        //To display an error when the external company is invalid
        partnerError: false,
        //To display an error when the user given can not be manager to the selected external company
        managerError: false,
        //To display an error when the user given can not be referent
        referentError: false,
        //To allow the button to be used for changing the referent
        referentValid: false,
        //To put text in red in modal
        isFailed: false,
        //used to disable the display of changing referent if the user does not have the right
        canChangeReferent: true
    }
  },
  computed: {
    ...mapState(['role', 'claims'])
  },
  watch: {
    //reset state of referent related variables when value changed
    referent: function(){
      this.referentValid = false
      this.referentError = false
      this.idReferent = ""
    },
    //reset state of new_manager related variables when value changed
    new_manager: function(){
      this.managerError = false
    }
  },
  mounted() {
     //inti tooltip
     Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .forEach(tooltipNode => new Tooltip(tooltipNode))
  },
  methods: {
    /**
     * Check if the given email in referent field correspond to a correct activ #INT user
     */
    async checkReferent(){
      this.referentError = false
      try{
        this.loadingReferent = true
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + this.referent)
        const headers = {
              "Accept": "application/json",
              "Content-Type": "application/json"
        }
        await axios.get(url,{headers}).then((res) => {
          if(res.data.profile.initials == "#INT" && res.data.status == "ACTIVE") {
            this.idReferent = res.data.id
            this.referentValid = true
          } else {
            this.referentError = true
          }
        })
        this.loadingReferent = false
      } catch (error) {
        this.loadingReferent = false
        this.referentError = true
      }
    },

    //Retieve the external company
    async searchPartner(){
      //Refreshin all states
      this.partner = this.$t('manage_partner.title_no_name')
      this.old_referent = ""
      this.old_referentId = ""
      this.referent = ""
      this.new_manager = ""
      this.partnerIds = []
      this.partnerManagers = []
      this.partnerSelected = false
      this.partnerError = false
      this.managerError = false
      this.loadingPartner = true
      const partnerName = this.field_partner_name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
      try{
        //get partner user group
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"USERS_" + partnerName + "\"")
        const headers = {
              "Accept": "application/json",
              "Content-Type": "application/json"
        }
        await axios.get(url,{headers}).then((res) => {
          if(res.data.length > 0) {
            this.partner = partnerName
            this.partnerIds.push(res.data[0].id)
            this.old_referent = res.data[0].profile.description
            this.referent = res.data[0].profile.description
          } else {
            this.partnerError = true
            throw ""
          }
        })

        //get partner manager group
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"MANAGER_" + partnerName + "\"")
        await axios.get(url,{headers}).then((res) => {
          if(res.data.length > 0) {
            this.partnerIds.push(res.data[0].id)
          } else {
            this.partnerError = true
            throw ""
          }
        })

        //get old partner referent
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + this.old_referent)
        await axios.get(url,{headers}).then((res) => {
          if(typeof res.data.length === 'undefined') {
            this.old_referentId = res.data.id
          } else {
            this.partnerError = true
            throw ""
          }
        })

        //get users of partner manager group
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/"+ this.partnerIds[1] +"/users")
        await axios.get(url,{headers}).then((res) => {
          if(res.data.length > 0) {
            for(let manager in res.data){
              this.partnerManagers.push({
                id: res.data[manager].id,
                login: res.data[manager].profile.login
              })
            }
          }
        })

        //set canChangeReferent here
        this.canChangeReferent = this.role == "Referent" && this.claims.email == this.old_referent ? false : true
        //set success state
        this.partnerSelected = true
        this.referentValid = true
      } catch (error) {
        console.error(error)
        //set error state
        this.partner = this.$t('manage_partner.title_no_name')
        this.loadingPartner = false
        this.partnerError = true
        this.partnerIds = []
      }
      this.loadingPartner = false
    },

    /**
     * Remove Selected managers from the list
     */
    async removeSelectedManagers(){
      this.loadingRemoveManager = true
      let url
      const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      //Retrieve selected options
      let options = document.getElementById("select_multiple_managers").selectedOptions
      //Remove user from Manager Group
      try{
        for(let option in options){
          if(options[option].value != undefined){
            url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + this.partnerIds[1] + "/users/" + options[option].value)
            await axios.delete(url, {headers})
            //Remove user from the list
            this.partnerManagers = this.partnerManagers.filter(function(value){
              return value.id != options[option].value
            })
          }
        }
      }catch(e){
        console.error(e)
        this.loadingRemoveManager = false
      }
      this.loadingRemoveManager = false
    },

    /**
     * Change the referent for the selected external company
     */
    async changeReferent(){
      //Refreshing related state
      this.isFailed = false
      this.message = this.$t('modal.waiting_msg')
      this.loading = true
      let newReferent = this.referent
      let newIdReferent = this.idReferent
      const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
      if(this.old_referent != newReferent) {
        try{
          //Get new referent user object
          let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + newReferent)
          const new_referent_response = await axios.get(url,{headers})

          if (new_referent_response.data.profile.initials == "#INT") {
            //Add referent to referents group (retrieve group then add referent in it)
            url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"Referents\"")
            const referentGroup = await axios.get(url, {headers})

            url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + referentGroup.data[0].id + "/users/" + newIdReferent)
            await axios.put(url, {}, {headers})

            //Edit USERS group description
            url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + this.partnerIds[0])
            let data_change_description = {
              "profile": {
                "name": "USERS_" + this.partner,
                "description": new_referent_response.data.profile.login
              }
            }
            await axios.put(url, data_change_description, {headers})

            //Edit All managerId attribut in USERS_ group with new email referent
            //// First : retrieve all id users of USERS_ group
            let partnerUsersIds = []
            url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + this.partnerIds[0] + "/users")
            await axios.get(url, {headers}).then((res) => {
              for(let user in res.data){
                partnerUsersIds.push(res.data[user].id)
              }
            })
            
            //// Second : Change attribut for all those users
            let user_data
            for(let id in partnerUsersIds){
              url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + partnerUsersIds[id])
              user_data = {
                "profile" : {
                  "managerId" : newReferent
                }
              }
              await axios.post(url, user_data, {headers})
            }
            //Set success state
            this.message = this.$t('manage_partner.success')
            this.loading = false
          } else {
            //Set failure state
            this.isFailed = true
            this.message = this.$t('manage_partner.error_referent')
            this.loading = false
            return
          }
        } catch(e) {
          //Set error state
          console.error(e)
          if(e.response.data.errorCauses === undefined){
            this.message = this.$t('error.contact_support')
          } else{
            this.message = e.response.data.errorCauses[0].errorSummary
          }
        }
      } else {
        //Set same user failure state
        this.message = this.$t("modal.same_user")
        this.loading = false
      }
    },

    /**
     * Add a Manager to the list / group
     */
    async addManager(){
      //Refreshing related state
      this.managerError = false
      this.loadingManager = true
      let manager = this.new_manager
      let valid = false
      let selectedManager
      try{
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/"+ this.partnerIds[0] +"/users")
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
        //Get the user to add from USERS external company group
        await axios.get(url,{headers}).then((res) => {
          for(let user in res.data){
            if(res.data[user].profile.login == manager){
              valid = true
              selectedManager = res.data[user]
            }
          }
        })
        //If the user exist and is not already manager
        if(valid && this.partnerManagers.filter(e => e.id === selectedManager.id).length == 0 ){
          //Add the user to de group Manager
          url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + this.partnerIds[1] + "/users/" + selectedManager.id)
          await axios.put(url, {}, {headers})
          //Add the user to the dynamic list
          this.partnerManagers.push({
            id: selectedManager.id,
            login: selectedManager.profile.login
          })
          //Sefreshing field
          this.new_manager = ""
        }else{
          //Set Error state
          this.managerError = true
        }
        this.loadingManager = false
      } catch (error) {
        //Set Error State
        this.managerError = true
        this.loadingManager = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.referent{
  margin-left: 5%;
  margin-right: 5%;
}

.form{
  text-align: left;
}
</style>