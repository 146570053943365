import { createStore } from 'vuex'

export default createStore({
  state: {
    claims: [],
    role: "",
    groupId: "",
    groups: [],
    partnerName: "",
    loading: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
})
