import { createRouter, createWebHistory } from 'vue-router'
import { isManager, isPortalAdmin, isReferent, isServiceDesk } from '@/utils/RoleVerification'
import axios from 'axios'
import store from '@/store'
import UploadCSV from '@/views/Users/UploadCSV'
import LoginView from '@/views/Auth/LoginView'
import UsersView from '@/views/Users/UsersView'
import PartnerView from '@/views/Partner/PartnerView'
import AddPartnerView from '@/views/Partner/AddPartnerView'
import PartnerManageView from '@/views/Partner/PartnerManageView'

const routes = [
  {
    name : "origin",
    path: '/',
    component: LoginView
  },
  {
    name : "users",
    path: '/users',
    component: UsersView,
    meta: {
      requiresAuth: true
    }
  },
  {
    name : "uploadCSV",
    path: '/uploadCSV',
    component: UploadCSV,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async () => {
      return await isPortalAdmin() || await isServiceDesk() || await isReferent() || isManager()
    }
  },
  {
    name : "partner",
    path: '/partner',
    component: PartnerView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async () => {
      return await isPortalAdmin() || await isServiceDesk()
    }
  },
  {
    name : "partnerManage",
    path: '/partner/manage',
    component: PartnerManageView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async () => {
      return await isPortalAdmin() || await isServiceDesk()
    }
  },
  {
    name : "partnerAdd",
    path: '/partner/add',
    component: AddPartnerView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async () => {
      return await isPortalAdmin() || await isServiceDesk()
    }
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (from.name != 'origin') store.state.loading = true
  var isAuthenticated
  await axios.get(process.env.VUE_APP_BACKEND_URI + "isAuthenticated", {}).then(res => {
    isAuthenticated = res.data
  })
  if(isAuthenticated == true) {
    if (from.name != 'origin')store.state.loading = false
    next()
  }else{
    throw {
      message: "user not authenticated"
    }
  }
})

router.onError(() => {
  window.location.replace(process.env.VUE_APP_BACKEND_URI + 'login')
})
export default router
