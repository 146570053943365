<template>
  <div id="app">
    <div v-if="globalLoading">
      <div class="text-center">
        <div class="spinner-border" style="width: 5rem; height: 5rem; margin-top:20rem" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="!globalLoading">
      <SideBarView />
    </div>
    <router-view  v-if="!globalLoading && !loading"/>
    <div v-if="loading && !globalLoading">
      <div class="text-center">
        <div class="spinner-border" style="width: 5rem; height: 5rem; margin-top:20rem" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBarView from "@/views/SideBarView.vue"
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: "app",
  components: {
    SideBarView
  },
  data() {
    return {
      globalLoading : true
    }
  },
  computed:{
    ...mapState(['claims', 'role', 'groups', 'loading']),
  },

  async beforeCreate () {
    //Get user info, store it in the store
    let url = process.env.VUE_APP_BACKEND_URI + "userinfo"
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    await axios.get(url, {headers}).then(res => {
      this.$store.state.claims = res.data
    })
    this.globalLoading = false
    //Get the groups and set the Role in vue ²
    let groups = await this.$store.state.claims.groups
    groups.includes("Service Desk") ? this.$store.state.role = "Service desk" : groups.includes("Portal Admins") ? this.$store.state.role = "IAM B2B Portal Admin" : this.getPartnerRole(groups)
    if(this.$store.state.role == "Manager"){
      let full_group_name = groups.find(element => element.includes("MANAGER_"));
      const full_group_names = full_group_name.split("_")
      let partner_name = full_group_names[full_group_names.length - 1]
      this.$store.state.groupName = partner_name
      try {
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?q=USERS_" + partner_name)
        await axios.get(url,{headers}).then(res => {
          this.$store.state.groupId = res.data[0].id
        })
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    getPartnerRole(groups){
      for(let group in groups){
        if(groups[group].includes("MANAGER_")){
          this.$store.state.role = "Manager"
          return
        }
        else if(groups[group].includes("Referents")){
          this.$store.state.role = "Referent"
          return
        }else {
          this.$store.state.role = "User"
        }
      }
    },
  }
}
</script>


<style lang="scss">
$primary: #92837a;
$btn-active-color: #FFF;
$body-color: #000;
@import "bootstrap";
@import "bootstrap/dist/css/bootstrap.min.css";

@font-face{
  font-display: auto;
  font-family: "Moet Hennessy";
  src: local("Moet Hennessy"), url('./assets/font/MoetHennessy_W_Rg.woff2') format("woff2"), url('./assets/font/MoetHennessy_W_Rg.woff') format("woff")
}
html, body{ 
  font-family: "Moet Hennessy";
}

#app {
  font-family: "Moet Hennessy", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  color: #FFF !important;
}

.btn-check:checked + .btn {
  color: #fff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #434343;

    &.router-link-exact-active {
      color: #909090;
    }
  }
}
</style>
