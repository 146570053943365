<template>
    <div class="title">
        <h1 v-bind="$attrs" class="mainTitle">
            {{ mainTitle }}
        </h1>
        <div v-if="optionnalTitle">
            <br>
            <h2>
                {{ optionnalTitle }}
            </h2>
        </div>
    </div>
</template>

<script>
export default {
    name:"HeadTitle",
    props:{
        mainTitle : {
            type: String,
            require: true
        },
        optionnalTitle : String
    }
}
</script>

<style scoped>
    .title {
        margin-top: 1.5em;
        margin-bottom: 5%;
    }
</style>