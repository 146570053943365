<template>
  <div>
    <div class="text-center">
      <div class="spinner-border" style="width: 5rem; height: 5rem; margin-top:20rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  components: {
  },
  created() {
    this.$router.push('/users')
  },
}
</script>

<style scoped lang="scss">
  .login {
    margin-top :200px;
  }
</style>
