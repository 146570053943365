<template>
  <div class="partner">
    <HeadTitle :mainTitle="$t('partnerView.title')"/>
    <div class="form">
      <div class="row align-items-center">
        <div class="col-4">
          <input type="text" v-model="partner" id="partner" class="form-control" v-on:keyup.enter="searchPartner" v-bind:class="{'is-invalid' : errorSearch}">
        </div>
        <div class="col-auto">
          <button @click="searchPartner" class="btn btn-primary">{{ $t('partnerView.btn_search') }}</button>
        </div>
        <div class="spinner-border text-primary col-auto" role="status" v-show="loading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div>
          <span style="color:red" v-if="errorSearch">{{ $t('error.unknown') }}</span>
        </div>
      </div>
    </div>
    <br>
    <div class="partnerList">
      <TableLite
        :is-slot-mode="true"
        :is-loading="table.isLoading"
        :columns="table.columns"
        :rows="table.rows"  
        :total="table.totalRecordCount"
        :sortable="table.sortable"
        @do-search="doSearch">
        <template v-slot:name="data">
        {{ data.value.name }}
      </template>
      </TableLite>
    </div>
    <div class="row align-items-center foot">
      <div class="col-12">
        <button @click="nextPartner" v-show="hasNext" class="btn btn-primary">{{ $t('partnerView.next') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import HeadTitle from '../../components/HeadTitle.vue'
import axios from 'axios'
import { reactive, computed } from 'vue'
import TableLite from "../../components/TableLite.vue";
export default {
  name: "PartnerView",
  components: {
    HeadTitle,
    TableLite
  },
  data(){

    //Table of the list of partners
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: this.$t('partnerView.externalCompany'),
          field: "externalCompany",
          width: "100%",
          sortable: true,
          isKey: true,
        },
        {
          label: this.$t('partnerView.MhReferent'),
          field: "MH_Referent",
          width: "75%"
        },
      ],
      rows: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "profile.name",
        sort: "asc",
      },
    });

    /**
     * Search Event
     */
    const doSearch = async (offset, limit, order, sort) => {
      //Refresh related states
      this.errorSearch = false
      this.loading = true;
      this.table.isLoading = true;
      let current_partner = this.partner.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
      try {
        let url

        if(current_partner == ""){
          url = encodeURI(process.env.VUE_APP_BACKEND_URI + 'api/v1/groups?limit=10&search=profile.name+sw+"USERS_"+and+(profile.name+lt+"USERS_SMPLGRP"+or+profile.name+gt+"USERS_SMPLGRP__")&sortBy=' + order + '&sortOrder=' + sort)
        } else {
          url = encodeURI(process.env.VUE_APP_BACKEND_URI + 'api/v1/groups?limit=10&search=profile.name+sw+"USERS_' + current_partner + '"+and+(profile.name+lt+"USERS_SMPLGRP"+or+profile.name+gt+"USERS_SMPLGRP__")&sortBy=' + order + '&sortOrder=' + sort)
        }
        if(this.hasNext && offset != 0){
          url += '&after=' + this.oldAfter
        }
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
        //Get the list of partners
        await axios.get(url,{headers}).then(res => {
          if(res.data.length == 0 && this.hasNext){ 
            this.hasNext = false
            this.oldAfter = "0"
            return
          } else if(res.data.length == 0 && !this.hasNext){
            this.table.rows = []
          }
          for(let partnerFound in res.data){
            res.data[partnerFound].profile.name = res.data[partnerFound].profile.name.replace("USERS_","")
            let partner_found = {
              id: res.data[partnerFound].id,
              externalCompany: res.data[partnerFound].profile.name,
              MH_Referent: res.data[partnerFound].profile.description
            }
            let index = parseInt(partnerFound) + parseInt(this.tableSize)
            this.table.rows[index] = partner_found
          }
          try{
            this.oldAfter = res.headers.link.split(", ")[1].split('groups?after=')[1].split('&limit=')[0]
            this.hasNext = true
          } catch (e) {
            this.oldAfter = "0"
            this.hasNext = false
          }
        })
        //Set success state
        this.loading = false
        this.table.isLoading = false;
      } catch (e) {
        //Set error state
        this.errorSearch = true
        this.loading = false
        this.table.isLoading = false
      }
      this.table.sortable.order = order
      this.table.sortable.sort = sort
    };

    //Trigger the search event with defined parameters
    const searchPartner = async () => {
      this.tableSize = 0
      this.table.rows = []
      doSearch(0, 10, "profile.name", "asc")
    }

    //prompt the following external companies
    const nextPartner = async () => {
      if(this.oldAfter == "0"){
        this.table.rows = []
        this.tableSize = 0
      }
      this.tableSize += 10
      doSearch(this.oldAfter, this.tableSize, "profile.name", "asc")
    }

    return {
      //variable contains 0 if no more external companies can be prompted. Otherwise contains the after value from the header link
      oldAfter: "0",
      //boolean value determining if their is more external companies or not
      hasNext: false,
      //user in the search field
      partner: "",
      //state used for the modal
      loading: false,
      //table variable
      table,
      //Scalable size of the table
      tableSize: 0,
      //To Return error
      errorSearch: false,
      //methods
      doSearch,
      searchPartner,
      nextPartner
    }
  },

  //Trigger do search when the view is mounted
  mounted(){
    this.doSearch(0, 10, "profile.name", "asc")
  }
};
</script>

<style scoped lang="scss">
.partner {
  margin-left: 5%;
  margin-right: 5%;
}

.foot{
  margin-bottom: 10px;
}

.form {
  text-align: left;
}
</style>