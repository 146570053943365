<template>
  <div class="reset-this">
    <SideBar 
        :menuTitle="$t('menu.title')"
        :menuLogo="image"
        :profileName="claims.name"
        :menuItems="menuItems"
        :profileRole="role"
    />
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue"
import { mapState } from 'vuex'
import { isManager, isPortalAdmin, isReferent, isServiceDesk } from '@/utils/RoleVerification'

export default {
  name: "SideBarView",
  data() {
    return {
        image: require('../assets/img/mh_logo.jpg'),
        menuItems : [],
    }
  },
  components: {
    SideBar,
  },
  computed:{
    ...mapState(['claims', 'role']),
  },
  async created(){
    if(await isPortalAdmin() || await isServiceDesk()){
      this.menuItems = [
        {
          link: '/users',
          name: this.$t('menu.item_search_users'),
          tooltip: 'Search for users',
          icon: 'bx-user',
        },
        {
          link: '/uploadCSV',
          name: this.$t('menu.item_upload_csv'),
          tooltip: 'Upload a CSV and Add, Edit or Deactivate Users',
          icon: 'bx-user-plus',
        },
        {
          link: '/partner',
          name: this.$t('menu.item_search_partner'),
          tooltip: 'Search for external company',
          icon: 'bx-wine',
        },
        {
          link: '/partner/add',
          name: this.$t('menu.item_add_company'),
          tooltip: 'Create a new external company',
          icon: 'bx-add-to-queue',
        },
        {
          link: '/partner/manage',
          name: this.$t('menu.item_edit_company'),
          tooltip: 'Edit Referent and/or Managers of an external company',
          icon: 'bx-transfer'
        },
      ]
    } else if (await isReferent() || await isManager()){
      this.menuItems = [
        {
          link: '/users',
          name: this.$t('menu.item_search_users'),
          tooltip: 'Search for users',
          icon: 'bx-user',
        },
        {
          link: '/uploadCSV',
          name: this.$t('menu.item_upload_csv'),
          tooltip: 'Upload a CSV and Add, Edit or Deactivate Users',
          icon: 'bx-user-plus',
        },
      ]
    }
  }
}
</script>

<style></style>