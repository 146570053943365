import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { createI18n } from "vue-i18n"
import "bootstrap"

//Load translation messages from folder locales for i18n package
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

axios.interceptors.request.use(
  function(response) {
    response.withCredentials = true;
    return response;
  }
);

axios.interceptors.response.use(
  undefined,
  function(err) {

    const { config } = err;

    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry wil status code is 409 (fix when okta make a change it can take time to be available, so some request needs to be resent until no 409error (409 is conflit error))
    // to use it in axios example : axios.post(url, data ,{headers,retry: 10, retryDelay: 3000})
    if (err.response.status != 409) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));

  }
);

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (err.response.status === 401) {
    window.location.reload()
  }
  return Promise.reject(err);
});


//Create i18n object with language detection (en by default if not fr or en)
const i18n = createI18n({
  locale: window.navigator.language.split('-')[0] || 'en',
  messages: loadLocaleMessages(),
  fallbackLocale: 'en'
})

//Create the application
createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueCookies)
  .use(VueAxios, axios)
  .mount('#app')