<template>
  <div class="addPartner">
    <HeadTitle :mainTitle="$t('add_partner.title')"/>
    <div class="form">
      <div class="row g-3 align-items-center">
        <div class="col-3">
          <label for="partner" class="col-form-label">{{ $t('add_partner.label_input_partner') }}</label>
        </div>
        <div class="col-3">
          <input type="text" data-bs-toggle="tooltip" data-bs-placement="right" :data-bs-title="$t('add_partner.tooltip_partner')" @focusout="ValidateName" v-model="partner_name" v-bind:class="{'is-invalid' : nameErrorLength || nameErrorSpecialChar || nameErrorExisting, 'is-valid' : nameValid}" id="partner" class="form-control">
        </div>
        <div class="col-4" v-show="nameErrorLength || nameErrorSpecialChar || nameErrorExisting">
          <span style="color:red" v-if="nameErrorLength">{{ $t('modal.error_length_partner') }}</span>
          <span style="color:red" v-if="nameErrorExisting">{{ $t('modal.error_existing_partner') }}</span>
          <span style="color:red" v-if="nameErrorSpecialChar">{{ $t('modal.error_special_char_partner') }}</span>
        </div>
        <div class="spinner-border text-primary " v-show="loadingCompany" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <br>
      <div class="row g-3 align-items-center">
        <div class="col-3">
          <label for="referent" class="col-form-label">{{ $t('add_partner.label_input_referent') }}</label>
        </div>
        <div class="col-5">
          <input type="email" data-bs-toggle="tooltip" data-bs-placement="right" :data-bs-title="$t('add_partner.tooltip_referent')" v-model="referent" id="referent" class="form-control" v-bind:class="{'is-invalid' : referentError, 'is-valid' : referentValid}" @focusout="checkReferent" placeholder="name@example.com">
        </div>
        <div class="col-auto" v-show="referentError">
          <span style="color:red">{{ $t('error.check_login') }}</span>
        </div>
        <div class="spinner-border text-primary " v-show="loadingReferent" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <br>
      <div class="d-grid gap-2 col-3 mx-auto">
        <button class="btn btn-primary" type="button" v-bind:class="{disabled: !nameValid || !referentValid}" @click="addPartner()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{{ $t('add_partner.btn_add') }}</button>
      </div>
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">{{ $t('modal.response') }}</h5>
            </div>
            <div class="modal-body">
              <span v-if="isFailed" style='color:red'>{{ message }}</span>
              <span v-else>{{ message }}</span>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" v-show="loading" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" v-bind:class="{disabled: loading}" data-bs-dismiss="modal">{{ $t('modal.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import HeadTitle from '../../components/HeadTitle.vue'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
export default {
  name: 'AddPartnerView',
  components: {
    HeadTitle
  },
  data() {
    return {
      //Name of the external company on the field
      partner_name : "",
      //Referent on the field
      referent : "",
      //Id of the retrieved referent
      idReferent: "",
      //Must be true while API calls are made (for loading spinner, disabled button,...)
      loading: false,
      //Default message for the modal
      message: this.$t('modal.waiting_msg'),
      //Loading for the search of the referent (spinner)
      loadingReferent: false,
      //Loading for the search of the referent (spinner)
      loadingCompany: false,
      //Manage activation of the add button
      referentValid: false,
      //To return error on the given referent
      referentError: false,
      //Manage activation of the add button
      nameValid: false,
      //To return error of specific error length on external company name
      nameErrorLength: false,
      //To return error of specific error esiting  on external company name
      nameErrorExisting: false,
      //To return error that no special character is allowed on the external company name
      nameErrorSpecialChar: false,
      //To put text in red in modal
      isFailed: false
    }
  },
  watch:{
    //reset state of referent related variables when value changed
    referent: function(){
      this.referentValid = false
      this.referentError = false
      this.idReferent = ""
    },
    //reset state of external company related variables when value changed
    partner_name: function(){
      this.nameValid = false
      this.nameErrorLength = false
      this.nameErrorSpecialChar = false
      this.nameErrorExisting = false
    }
  },
  mounted() {
     //inti tooltip
     Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .forEach(tooltipNode => new Tooltip(tooltipNode))
  },
  methods: {
    /**
     * Check if the given email in referent field correspond to a correct activ #INT user
     */
    async checkReferent(){
      try{
        this.loadingReferent = true
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/users/" + this.referent)
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
        await axios.get(url,{headers}).then((res) => {
          if(res.data.profile.initials == "#INT" && res.data.status == "ACTIVE") {
            this.referentValid = true
            this.idReferent = res.data.id
          } else {
            this.referentError = true
          }
        })
        this.loadingReferent = false
      } catch (error) {
        this.loadingReferent = false
        this.referentError = true
      }
    },

  /**
   * Check if the External comapny name match the defined rules
   */
   async ValidateName(){
    this.loadingCompany = true
      if(this.partner_name.length < 3 || this.partner_name.length > 100){
        this.nameErrorLength = true
      } else if(this.containsSpecialChars(this.partner_name)){
        this.nameErrorSpecialChar = true
      } 
      
      let partnerName = this.partner_name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
      try{
        let url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"USERS_" + partnerName + "\"")
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
        await axios.get(url,{headers}).then((res) => {
          if(res.data.length > 0) {
        this.nameErrorExisting = true
        this.nameValid = false
          }
          else {
        this.nameErrorExisting = false      
        this.nameValid = true
        this.loadingCompany = false
          }
        })
      } catch (error) {
        this.nameErrorLength = true
        this.nameValid = false
        this.loadingCompany = false
      }
      
    this.loadingCompany = false
    },

    /**
     * Add the external company
     */
    async addPartner(){
      //Refresh states
      this.isFailed = false
      this.message = this.$t('modal.waiting_msg')
      this.loading = true
      let partnerName = this.partner_name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()
      let selectedReferent = this.referent
      let currentIdReferent = this.idReferent

      try {
        //Retrieve referent group
        let url
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }

        //Number of users in Users partner group
        //Must be set to 0 to continue and retrieve a valid set of groups to add an external company
        let usersUsersGroup = ["not blank"]
        let indexGroup = -1

        //Retrieve list of partner users group
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+sw+\"USERS_SMPLGRP_\"")
        let usersGroup = await axios.get(url, {headers})

        //While number of users in group is not 0, continue to find an empty group
        while(usersUsersGroup != 0){
          indexGroup++
          //If the group is undefined, no more groups are available
          if(typeof(usersGroup.data[indexGroup]) === 'undefined') throw this.$t("modal.no_more_group")

          //Get user of supposed empty groups
          var fullpath = usersGroup.data[indexGroup]._links.users.href
          url = process.env.VUE_APP_BACKEND_URI + "api/v1/" +  fullpath.split("/api/v1/")[1]
          await axios.get(url, {headers}).then((res) => {
            usersUsersGroup = res.data.length
          })
        }
        //Retrive corresponding blank partner manager group
        const userGroupName = usersGroup.data[indexGroup].profile.name;

        //Retrieve the custom id of the empty group
        const groupName = userGroupName.slice(userGroupName.indexOf('_') + 1)
        
        //Retrieve the related manager group based on the custom id
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"MANAGER_" + groupName + "\"")
        let managerGroup = await axios.get(url, {headers})

        //Retrieve data of the referent group
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups?search=profile.name+eq+\"Referents\"")
        const referentGroup = await axios.get(url, {headers})

        //Change name & desciption
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + usersGroup.data[indexGroup].id)
        let data = {
          "profile": {
            "name": "USERS_" + partnerName,
            "description": selectedReferent
          }
        }
        await axios.put(url, data, {headers})

        //Change name of the manager group
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + managerGroup.data[indexGroup].id)
        data = {
          "profile": {
            "name": "MANAGER_" + partnerName,
            "description": managerGroup.data[indexGroup].profile.description
          }
        }
        await axios.put(url, data, {headers})
        
        //add referent in group referent
        url = encodeURI(process.env.VUE_APP_BACKEND_URI + "api/v1/groups/" + referentGroup.data[indexGroup].id + "/users/" + currentIdReferent)
        await axios.put(url, {}, {headers})

        //Give success state to the Modal
        this.message = this.$t('modal.partner_created') + partnerName
        this.loading = false
        this.partner_name = ""
        this.referent= ""
      } catch (error) {
        console.error(error)
        //if error undefined => default error message to modal
        if(error.response.data.errorCauses === undefined){
          this.message = this.$t('error.basic')
        //if error defined => retrieve from Okta and return it in modal
        } else {
          this.message = error.response.data.errorCauses[0].errorSummary
        }
        //five error state to modal
        this.isFailed = true
        this.loading = false
      }
    },
    
    //return if contains special characters
    containsSpecialChars(str) {
      const specialChars =/[`!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/;
      return specialChars.test(str);
    },
  }
}
</script>

<style scoped lang="scss">
.addPartner{
  margin-left: 5%;
  margin-right: 5%;
}

.form{
  text-align: left;
}
</style>