import store from '../store'

async function getRole() {
    let role = await store.state.role
    while (role.length <= 5 || typeof(role) === 'undefined' ){
        await new Promise(r => setTimeout(r, 200))
        role = await store.state.role
    }
    return role
}

export const isReferent = async () => {
    return await getRole() == "Referent"
}

export const isManager = async () => {
    return await getRole() == "Manager"
}

export const isServiceDesk = async () => {
    return await getRole() == "Service desk"
}

export const isPortalAdmin = async () => {
    return await getRole() == "IAM B2B Portal Admin"
}